/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. */
/* SPDX-License-Identifier: MIT-0 */

:root {
  --video-width: 85.4rem;
}

.video-elem {
  top: 0;
}

.video-container {
  display: block;
  width: var(--video-width);
  height: fit-content;
}

.video-metadata-container {
  font-family: var(--font-mono);
  z-index: 999;
  right: 2rem;
  top: 2rem;
}

.video-metadata-item {
  font-weight: 400;
  font-size: .9rem;
  height: 100%;
  position: relative;
  top: 0;
}

@media (max-width: 480px) { /* Smaller Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) { /* Small Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 767px) and (max-width: 1024px) { /* Large Screens */
  :root {
    --video-width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) { /* Large Screens */
  :root {
    --video-width: 64rem;
  }
}
