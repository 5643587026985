/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. */
/* SPDX-License-Identifier: MIT-0 */

.modal-container {
  display: flex;
  justify-content: center;
  height: 515px;
  width: 350px;
  position: absolute;
  z-index: 999;
}

.modal-content {
  border: solid 1px var(--color-border-base);
  border-radius: var(--radius-small);
  background: var(--color-bg-modal);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal-close {
  top: 1.5rem;
  left: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.modal-product-buy-now__price::after {
  content: ' \2022';
}

.modal-close:hover {
  cursor: pointer;
}

.modal-content img {
  height: 196px;
}

.modal-content .product-image {
  display: flex;
  justify-content: center;
}

.modal-product-name {
  font-weight: bold;
}

.modal-product-image {
  min-height: 40px;
  overflow: hidden;
}

.modal-product-description {
  overflow-y: auto;
}

.modal-product-actions {
  margin-top: auto;
}

@media(max-width:1340px) {
  .modal-container {
    width: 100%;
    height: 100%;
    max-width: unset;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .modal-content .name {
    padding: 20px 20px 0 20px;
  }

  .modal-content .description {
    margin: 20px 20px 0 20px;
    padding: 0;
    overflow-y: auto;
  }

  .modal-content .actions {
    padding: 20px;
  }

  .modal-content {
    position: relative;
    top: 15%;
    height: 600px;
    width: 500px;
    max-height: 70%;
    border: solid 1px var(--color-border-base);
    border-radius: var(--radius-small);
    background: var(--color-bg-modal);
  }

  .modal-content img {
    height: 100%;
    width: 100%;
  }
}
