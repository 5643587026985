/* Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved. */
/* SPDX-License-Identifier: MIT-0 */

/* index.css overrides */
:root {
  /* Theming */
  --color-bg-base: var(--color-bg-alt-2);
  --color-bg-alt: var(--color-bg-body);

  --color--primary:  var(--color-bg-inverted);
  --color--secondary: var(--color-bg-inverted);

  --color-bg-modal-overlay: #E3E3EAE6;

  /* Responsive */
  --video-grid-template: 1fr 34rem;
  --video-grid-gap: 2rem;
}

#root {
  width: 100%;
  height: 100%;
}

.video-product-content {
  display: grid;
  grid-template-columns: var(--video-grid-template);
  grid-gap: var(--video-grid-gap);
  grid-template-rows: min-content 1fr; /* NEW */
}

@media (max-width: 480px) { /* Smaller Screens */
  :root {
    --video-grid-template: 1fr;
  }
}

@media (min-width: 480px) and (max-width: 767px) { /* Small Screens */
  :root {
    --video-grid-template: 1fr;
  }
}

@media (min-width: 767px) and (max-width: 1024px) { /* Large Screens */
  :root {
    --video-grid-template: 1fr;
  }
}
